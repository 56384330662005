// eslint-disable-next-line import/no-unresolved
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import ReactHtmlParser from 'react-html-parser';

import { Icon } from '@powdr/components';
import { ColorProfiles } from '@powdr/constants';
import { componentFactory } from '@powdr/utils';

import {
  StyledCollapsePanel, ExpandButton, PanelBodyWrapper,
  TitleText, CopyText, HeaderLeft, HeaderRight, PanelHeader,
  ComponentWrapper,
  HeaderCenter,
} from './styles';

export const CollapsePanel = ({
  id,
  cpHeader,
  cpBody,
  relationships,
  children,
  panelColorProfile,
  parentColorProfile,
}) => {
  const [isPanelOpen, setPanelOpen] = useState(false);
  const panelHeader = cpHeader || relationships?.panelHeader || null;
  const panelBody = cpBody || relationships?.panelBody || null;
  const bodyComponents = panelBody?.relationships?.bodyContent || children || [];
  const trueColorProfile = panelColorProfile || parentColorProfile || ColorProfiles.BASE;
  const animationSpeed = 500; // ms
  const adjustPadding = (trueColorProfile === (parentColorProfile || ColorProfiles.BASE));

  if (!panelHeader) return null;

  return (
    <StyledCollapsePanel
      key={id}
      $colorProfile={trueColorProfile}
      $adjustPadding={adjustPadding}
    >
      <PanelHeader
        className="panel-header"
        isContainer={panelHeader?.isContainer}
        onClick={() => setPanelOpen(!isPanelOpen)}
      >
        <HeaderLeft>
          <TitleText colorProfile={trueColorProfile}>{panelHeader?.title}</TitleText>
          {(panelHeader?.headerCopy) && (
            <CopyText colorProfile={trueColorProfile}>
              {panelHeader?.headerCopy}
            </CopyText>
          )}
        </HeaderLeft>
        {(panelHeader?.subheader) && (
          <HeaderCenter colorProfile={trueColorProfile}>
            {ReactHtmlParser(panelHeader.subheader)}
          </HeaderCenter>
        )}
        <HeaderRight>
          <ExpandButton
            colorProfile={trueColorProfile}
            hasText={(panelHeader?.buttonText)}
            $animationSpeed={animationSpeed}
          >
            {panelHeader?.buttonText}
            <Icon className={classNames('expand-icon', { open: isPanelOpen })} name="ui-chevron-down" width={15} />
          </ExpandButton>
        </HeaderRight>
      </PanelHeader>
      <PanelBodyWrapper>
        <AnimateHeight duration={animationSpeed} height={(isPanelOpen) ? 'auto' : 0}>
          <ComponentWrapper isContainer={panelBody?.isContainer}>
            {(children) && bodyComponents}
            {(relationships) && componentFactory(bodyComponents, trueColorProfile)}
          </ComponentWrapper>
        </AnimateHeight>
      </PanelBodyWrapper>
    </StyledCollapsePanel>
  );
};

CollapsePanel.propTypes = {
  id: PropTypes.string.isRequired,
  cpHeader: PropTypes.shape(),
  cpBody: PropTypes.shape(),
  relationships: PropTypes.shape(),
  panelColorProfile: PropTypes.string,
  parentColorProfile: PropTypes.string,
  children: PropTypes.node,
};

CollapsePanel.defaultProps = {
  panelColorProfile: null,
  parentColorProfile: null,
  relationships: null,
  cpHeader: null,
  cpBody: null,
  children: null,
};
