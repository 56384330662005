import PropTypes from 'prop-types';
import React, {
  useContext, useRef, useEffect, useState,
} from 'react';

import { TvAlerts } from '@powdr/components';
import { Symbols } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useCurrentTemperature } from '@powdr/hooks';
import { doubleDigitizer, formatDate } from '@powdr/utils';

import {
  AltIcon,
  ButtonWrapper,
  Clock, ComponentWrapper, CurrentTempDisplay, CurrentTempIcon, CurrentTempValue,
  LogoWrapper, NavBarInfoSection, NextButton, PauseButton,
  PreviousButton,
  SlideTitle, StyledTvMain, TvNav, TvNavBar,
  TvNavItem,
  TvNavItemIndex,
  TvNavItemInner,
} from './styles';

export const TvMain = ({
  className,
  title,
  alternateTVLogo,
  lastUpdatedTime,
  navPages,
  activeItemData,
  children,
  pauseState,
  setPauseState,
  nextItem,
  previousItem,
  isAlertOverlayActive,
  overlayAlerts,
}) => {
  const { svgs } = useContext(AppContext);
  const [time, setTime] = useState();
  const clockIntervalRef = useRef();
  const getActiveItemIndex = () => navPages.findIndex((i) => activeItemData.link.includes(i.link));
  const temperatureIntervalFrequency = 3; // minutes
  const temperatureData = useCurrentTemperature(temperatureIntervalFrequency);
  const maxNavBarItems = 3;

  useEffect(() => {
    clockIntervalRef.current = setInterval(() => {
      const date = new Date();
      const dateInfo = formatDate(date);

      setTime(`${dateInfo.time.hours}:${dateInfo.time.minutes} ${dateInfo.time.ampm.toUpperCase()}`);
    }, 1000);
  }, []);

  return ((
    <StyledTvMain className={className}>
      {(navPages?.length > 0) && (
        <ButtonWrapper>
          <PauseButton onClick={() => setPauseState(!pauseState)}>Pause Rotation</PauseButton>
          <PreviousButton onClick={() => previousItem()}>Previous Item</PreviousButton>
          <NextButton onClick={() => nextItem()}>Next Item</NextButton>
        </ButtonWrapper>
      )}
      <SlideTitle>
        {(!isAlertOverlayActive) && <h1>{title}</h1>}
        {(lastUpdatedTime) && <h2>{`Last Updated at ${lastUpdatedTime}`}</h2>}
      </SlideTitle>
      <LogoWrapper $isAltLogo={alternateTVLogo}>
        {(alternateTVLogo) && <AltIcon name={alternateTVLogo} />}
        {(svgs?.SiteLogoTv && !alternateTVLogo) && <svgs.SiteLogoTv />}
      </LogoWrapper>
      <ComponentWrapper>
        {(!isAlertOverlayActive || (isAlertOverlayActive && overlayAlerts?.length === 0))
          ? children
          : (
            <TvAlerts
              alerts={overlayAlerts}
              displayTime={10 * overlayAlerts.length}
            />
          )}
      </ComponentWrapper>
      {(!isAlertOverlayActive) && (
        <TvNavBar>
          <TvNav>
            {navPages
              ?.slice(
                Math.floor(getActiveItemIndex() / maxNavBarItems) * maxNavBarItems,
                Math.floor(getActiveItemIndex() / maxNavBarItems) + maxNavBarItems,
              )
              ?.map((i, idx) => (
                <TvNavItem key={i.link} $isActive={activeItemData.link.includes(i.link)}>
                  <TvNavItemInner $isActive={activeItemData.link.includes(i.link)}>
                    <TvNavItemIndex>
                      {doubleDigitizer(
                        idx
                        + 1
                        + (Math.floor(getActiveItemIndex() / maxNavBarItems) * maxNavBarItems),
                      )}
                    </TvNavItemIndex>
                    {` / ${i.name}`}
                  </TvNavItemInner>
                </TvNavItem>
              ))}
          </TvNav>
          <NavBarInfoSection>
            <CurrentTempDisplay>
              <CurrentTempValue>
                {`${(!temperatureData || temperatureData?.value === null) ? Symbols.DOUBLE_DASH : Math.round(temperatureData.value)}${Symbols.DEGREE}`}
              </CurrentTempValue>
              <CurrentTempIcon name={temperatureData?.icon} />
            </CurrentTempDisplay>
            <Clock>{time}</Clock>
          </NavBarInfoSection>
        </TvNavBar>
      )}
    </StyledTvMain>
  ));
};

TvMain.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  navPages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  alternateTVLogo: PropTypes.string,
  activeItemData: PropTypes.shape({
    link: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  lastUpdatedTime: PropTypes.string,
  alertData: PropTypes.shape({
    enabled: PropTypes.bool,
    message: PropTypes.string,
    subMessage: PropTypes.string,
    iconName: PropTypes.string,
    iconColor: PropTypes.string,
  }).isRequired,
  pauseState: PropTypes.bool,
  setPauseState: PropTypes.func,
  nextItem: PropTypes.func,
  previousItem: PropTypes.func,
  isAlertOverlayActive: PropTypes.bool.isRequired,
  overlayAlerts: PropTypes.instanceOf(Array),
};

TvMain.defaultProps = {
  className: '',
  alternateTVLogo: null,
  lastUpdatedTime: null,
  pauseState: false,
  setPauseState: () => {},
  nextItem: () => {},
  previousItem: () => {},
  overlayAlerts: [],
};
