import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';

import { getImageAltText } from '@powdr/utils';

import {
  GroupName, GuideBlock, GuideName, GuidePhoto, GuideTextBlock, GuideTitle, Manifest, Name, NameList, StyledTvFlightManifest,
} from './styles';

export const TvFlightManifest = ({
  className,
  displayTime,
  relationships,
}) => {
  const manifests = useMemo(() => relationships?.manifests || [], [relationships]);
  const itemsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(0);
  const [numPages] = useState(Math.ceil(manifests.length / itemsPerPage));
  const getCurrentItems = useCallback(
    () => manifests.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage,
    ),
    [currentPage, manifests],
  );
  const [currentItems, setCurrentItems] = useState(getCurrentItems());
  const flightManifestRotationRef = useRef();

  useEffect(() => {
    const perPageDisplayTime = (displayTime * 1000) / numPages;
    flightManifestRotationRef.current = setTimeout(() => {
      setCurrentPage((currentPage === numPages - 1) ? 0 : currentPage + 1);
      setCurrentItems(getCurrentItems());
    }, perPageDisplayTime);

    return () => {
      clearTimeout(flightManifestRotationRef.current);
    };
  }, [currentPage, numPages, displayTime, manifests, getCurrentItems]);

  if (!manifests || manifests.length === 0) return null;

  return (
    <StyledTvFlightManifest className={className}>
      {currentItems.map((manifest) => (
        <Manifest key={manifest?.id}>
          <GroupName>{manifest.groupName}</GroupName>
          <GuideBlock>
            <GuideTextBlock>
              <GuideTitle>guide</GuideTitle>
              <GuideName>{manifest?.relationships?.guide?.title}</GuideName>
            </GuideTextBlock>
            <GuidePhoto
              image={manifest?.relationships?.guide?.relationships?.field_referenced_image?.relationships?.image?.gatsbyImage}
            />
          </GuideBlock>
          <NameList>
            <Name>name 1</Name>
            <Name>name 2</Name>
            <Name>name 3</Name>
          </NameList>
        </Manifest>
      ))}
    </StyledTvFlightManifest>
  );
};

TvFlightManifest.propTypes = {
  className: PropTypes.string,
  displayTime: PropTypes.number,
  relationships: PropTypes.shape({
    manifests: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

TvFlightManifest.defaultProps = {
  className: '',
  displayTime: null,
};
