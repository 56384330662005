import styled from 'styled-components';

import {
  ThemeProps as Prop, FontFamily,
} from '@powdr/constants';
import { getFontStyles } from '@powdr/utils';

export const StyledRatesAndDatesTabs = styled.div``;

export const Tabs = styled.div`
  display: flex;
  gap: 50px;
  margin-bottom: 25px;
`;

export const TabButton = styled.button`
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, '26px')};
  ${({ $isActive }) => ($isActive) && ' text-decoration: underline;'};
`;

export const TabContentWrapper = styled.div``;
