/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { DorMeasurementItem } from './components';
import { StyledDorMeasurements } from './styles';

export const DorMeasurements = ({
  className,
  relationships,
  columnOption,
  rowOption,
  parentColorProfile,
  isFromTV,
}) => {
  const measurements = relationships?.measurements || [];

  return (
    <StyledDorMeasurements
      className={className}
      $numItems={measurements.length}
      $secondRowItems={measurements.length % 4}
      $columns={columnOption || 2}
      $rows={rowOption || null}
    >
      {measurements?.map((m, idx) => (
        <DorMeasurementItem
          // eslint-disable-next-line react/no-array-index-key
          key={`${m.id}-${idx}`}
          className={classNames({ 'tv-measurement-item': isFromTV })}
          measurementLabel={m?.measurementLabel || null}
          measurementType={m?.measurementType || null}
          measurementDisplayType={m?.measurementDisplayType || null}
          ignoreLiftTypes={m?.ignoreLiftTypes || []}
          ignoreTrailTypes={m?.ignoreTrailTypes || []}
          ignoreGroomStatus={m?.ignoreGroomStatus || []}
          liftsTrailsMeasurement={m?.liftsTrailsMeasurement || null}
          snowReportMeasurement={m?.snowReportMeasurement || null}
          icon={m?.icon || null}
          lift={m?.lift || {}}
          trail={m?.trail || {}}
          road={m?.road || {}}
          terrain={m?.terrain || {}}
          sensor={(m?.sensor?.value) ? m?.sensor?.value.replace(/ .*/, '') : null}
          season={m?.filterBySeason || null}
          parentColorProfile={parentColorProfile}
        />
      ))}
    </StyledDorMeasurements>
  );
};

DorMeasurements.propTypes = {
  className: PropTypes.string,
  columnOption: PropTypes.number,
  rowOption: PropTypes.number,
  relationships: PropTypes.shape({
    measurements: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  parentColorProfile: PropTypes.string,
  isFromTV: PropTypes.bool,
};

DorMeasurements.defaultProps = {
  className: '',
  columnOption: 2,
  parentColorProfile: null,
  isFromTV: false,
  rowOption: null,
};
