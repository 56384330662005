import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { ImageAttribution } from '@powdr/components';
import { Icon } from '@powdr/components/icon';
import { Link } from '@powdr/components/link';
import { BlockTypes, Breakpoints, ThemeProps as Prop } from '@powdr/constants';
import { useWindowResize } from '@powdr/hooks';
import { ContentBlockType } from '@powdr/model';
import {
  themeUtil, getBackgroundImageSrc, rgbaTransparencyToHex, clamp,
} from '@powdr/utils';

const CPNT = BlockTypes.FLOAT;

// styled components
const ContentBlockFloat = styled(Link)`
  display: grid;
  position: relative;
  width: 100%;
  min-height: 330px;
  background-image: ${({ bgImage }) => `url('${bgImage}')`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .triangle-element {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-left-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile) + rgbaTransparencyToHex(0.75)};
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-style: solid;
    border-width: 0 0 115px 115px;

    ${Breakpoints.mobile(css`
      border-width: 0 0 85px 85px;
    `)}
  }

  &:hover, &:focus {
    .cb-content:not(.overlay) {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile) + rgbaTransparencyToHex(1)};

      .cb-title, .cb-copy {
        color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
      }

      &:before {
        transform: translateY(0);
      }
    }
  }

  &:focus .button {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    color: ${({ colorProfile }) => themeUtil(Prop.BTN_TXT_HOVER, CPNT, colorProfile)};
    outline: 2px solid ${({ colorProfile }) => themeUtil(Prop.BTN_BG_HOVER, CPNT, colorProfile)};
    outline-offset: 2px;
  }

  .accent-icon {
    position: absolute;
    top: 13px;
    left: 10px;

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.ICON, CPNT, colorProfile)};
    }

    ${Breakpoints.mobile(css`
      top: 10px;
      left: 8px;
    `)}
  }

  .cb-content {
    background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile) + rgbaTransparencyToHex(0.75)};
    position: absolute;
    bottom: 16px;
    right: 16px;
    text-align: left;
    width: 75%;
    height: auto;
    padding: 40px;
    z-index: 1;
    overflow: hidden;

    &:before:not(.overlay) {
      background-color: ${({ colorProfile }) => themeUtil(Prop.BACKGROUND, CPNT, colorProfile)};
      transition: .3s ease-in-out;
      transform: translateY(100%);
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    .cb-title, .cb-copy {
      transition: all .3s ease-in-out;
      max-width: 100%;
    }

    .cb-title {
      margin-bottom: 15px;
      line-height: normal;
      ${({ $isFullHeight }) => (!$isFullHeight) && clamp(3)};
    }

    .cb-copy:not(.no-btn) {
      ${({ $isFullHeight }) => (!$isFullHeight) && clamp(3)};
      margin-bottom: 15px;
    }

    .cb-copy:not(.overlay) {
      color: ${({ colorProfile }) => themeUtil(Prop.CONTENT, CPNT, colorProfile)};
    }

    .cb-copy.no-btn {
      margin-bottom: 0;
    }

    .button {
      display: inline-block;
    }
  }

  @keyframes slideInUp {
    from {
      transform: translate3d(0, 100%, 0);
      visibility: visible;
    }

    to {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes slideOutDown {
    from {
      transform: translate3d(0, 0, 0);
    }

    to {
      transform: translate3d(0, 100%, 0);
      visibility: hidden;
    }
  }
`;

const StyledIcon = styled(Icon)`
  height: 40px;

  ${Breakpoints.mobile(css`
    height: 30px;
  `)}
`;

export const Float = ({
  title, copy, blockType, image, columnOption, accentIconName, ariaLabel,
  buttonEnabled, buttonLink, buttonText, buttonTarget, buttonClick,
  isOverlayEnabled, colorProfile, isStaticImageLink, isFullHeight,
}) => {
  const [bgImage, setBgImage] = useState('');
  const resize = useWindowResize();

  useEffect(() => {
    if (resize.windowWidth) {
      setBgImage(
        (!isStaticImageLink)
          ? getBackgroundImageSrc(image?.src, resize.windowWidth, columnOption)
          : image.src,
      );
    }
  }, [resize.windowWidth]);

  return (
    <ContentBlockFloat
      href={buttonLink}
      target={buttonTarget}
      bgImage={bgImage}
      colorProfile={colorProfile}
      onClick={() => buttonClick()}
      aria-label={ariaLabel}
      role="button"
      className={`content-block-${blockType} `}
      $isFullHeight={isFullHeight}
    >
      <ImageAttribution maxWidth="23%">{image?.attribution}</ImageAttribution>
      {(accentIconName) && (
        <>
          <div className="triangle-element" />
          <div className="accent-icon">
            <StyledIcon name={accentIconName} />
          </div>
        </>
      )}
      <div className={classNames('cb-content', { overlay: isOverlayEnabled })}>
        {(title) && <h3 className="cb-title">{title}</h3>}
        {(copy && buttonEnabled) && <p className="cb-copy">{copy}</p>}
        {(copy && !buttonEnabled) && <p className="no-btn cb-copy">{copy}</p>}
        {(buttonEnabled && buttonText) && (
          <div className="button with-background ">
            {buttonText}
          </div>
        )}
      </div>
    </ContentBlockFloat>
  );
};

Float.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
