/* eslint-disable no-nested-ternary */
import {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  DorMeasurementFieldNames, LiftsTrailsMeasurementTypes, LiftTrailStatus, TrailsPropertyKeys,
} from '@powdr/constants';
import { getTrails } from '@powdr/stores';
import { filterLiftTrailItemsBySeason } from '@powdr/utils';

export const useTrailsMeasurements = (
  ignoreTrailTypes,
  ignoreGroomStatus,
  measurementSpecification,
  season,
) => {
  const dispatch = useDispatch();
  const useTrailsData = useSelector((state) => state.trails);
  const { data } = useTrailsData;
  const [trailsData, setTrailsData] = useState(null);

  const filterTrails = useCallback(
    (d, checkOpen, checkGroomStatus) => d
      .filter((t) => t.include === true)
      .filter((t) => !ignoreTrailTypes?.includes(t.type))
      .filter((t) => ((checkGroomStatus)
        ? !ignoreGroomStatus?.includes(t.groom_status)
        : true))
      .filter((t) => (
        (checkOpen)
          ? (t.status === LiftTrailStatus.OPEN || t.status === LiftTrailStatus.EXPECTED)
          : true
      )),
    [ignoreTrailTypes, ignoreGroomStatus],
  );

  const propertyReduceAdder = useCallback(
    (d, property) => d
      .reduce((accum, current) => {
        if (current?.segments?.length > 0) {
          return accum + propertyReduceAdder(current?.segments, property);
        }

        return accum + current.properties[property];
      }, 0),
    [],
  );

  const getMeasurements = useCallback(
    () => (
      (measurementSpecification === LiftsTrailsMeasurementTypes.MILES_OF_SKIING
        || measurementSpecification === LiftsTrailsMeasurementTypes.ACRES_OF_SKIING)
        ? {
          [DorMeasurementFieldNames.OPEN]: Math.round(propertyReduceAdder(
            filterLiftTrailItemsBySeason(filterTrails(data, true, true), season),
            TrailsPropertyKeys[measurementSpecification],
          )),
          [DorMeasurementFieldNames.TOTAL]: Math.round(propertyReduceAdder(
            filterLiftTrailItemsBySeason(filterTrails(data, false, false), season),
            TrailsPropertyKeys[measurementSpecification],
          )),
        }
        : {
          [DorMeasurementFieldNames.OPEN]: filterLiftTrailItemsBySeason(
            filterTrails(data, true, true),
            season,
          ).length,
          [DorMeasurementFieldNames.TOTAL]: filterLiftTrailItemsBySeason(
            filterTrails(data, false, false),
            season,
          ).length,
        }),
    [data, filterTrails, measurementSpecification, propertyReduceAdder, season],
  );

  useEffect(() => {
    dispatch(getTrails());
  }, [dispatch]);

  useEffect(() => {
    if (data && data?.length > 0) {
      setTrailsData(getMeasurements());
    }
  }, [data, getMeasurements]);

  return trailsData;
};
