import axios from 'axios';

import { FetchStatus } from '@powdr/constants';
import { getNodeApiDomain } from '@powdr/utils';

import { SENSORS_PENDING, SENSORS_SUCCESS, SENSORS_ERROR } from '../types';

export const getSensors = () => async (dispatch, getState) => {
  const sensorsStatus = getState().sensors.status;

  if (sensorsStatus !== FetchStatus.LOADING && sensorsStatus !== FetchStatus.PENDING) {
    try {
      dispatch({
        type: SENSORS_PENDING,
        payload: [],
      });
      const res = await axios.get(`${getNodeApiDomain()}/dor/sensors`);
      dispatch({
        type: SENSORS_SUCCESS,
        payload: Object.fromEntries(
          Object.entries(res.data).map(
            ([key, value]) => [key, value[value.length - 1]],
          ),
        ),
      });
    } catch (error) {
      dispatch({
        type: SENSORS_ERROR,
        payload: error,
      });
    }
  }
};
