import styled, { css } from 'styled-components';

import { Icon } from '@powdr/components';
import {
  ThemeProps as Prop, Breakpoints, FontFamily, FontSize,
  ColorProfiles,
  Components,
} from '@powdr/constants';
import { getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.CAMP_DETAILS;

export const StyledCampDetails = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(7, auto);
  grid-gap: 10px;

  .detail-1 { grid-area: 1 / 1 / 2 / 3; }
  .detail-2 {
    grid-area: 2 / 1 / 3 / 2;

    &.missing-sibling {
      grid-area: 2 / 1 / 3 / 3;
    }
  }
  .detail-3 { grid-area: 2 / 2 / 3 / 3; }
  .detail-4 { grid-area: 3 / 1 / 4 / 3; }
  .detail-5 { grid-area: 4 / 1 / 5 / 3; }
  .detail-6 { grid-area: 5 / 1 / 6 / 3; }
  .detail-7 { grid-area: 6 / 1 / 7 / 3; }
  .detail-8 { grid-area: 7 / 1 / 8 / 3; }
`;

const SharedBlockStyles = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  color: ${() => themeUtil(Prop.CONTENT, CPNT, ColorProfiles.SECONDARY)};
  background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT, ColorProfiles.SECONDARY)};
`;

export const HalfBlock = styled.div`
  ${SharedBlockStyles}
`;

export const Block = styled.div`
  ${SharedBlockStyles}
`;

export const BlockTitle = styled.h3`
  color: ${() => themeUtil(Prop.HEADER, CPNT, ColorProfiles.SECONDARY)};
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.REG50, undefined, undefined)};
`;

export const TitleBlock = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  text-align: center;
  background-color: ${() => themeUtil(Prop.BACKGROUND, CPNT, ColorProfiles.PRIMARY)};
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '32px', undefined, undefined)};
`;

export const CampText = styled.div``;

export const CampTitle = styled.h4`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, FontSize.MED30, undefined, undefined)};
`;

export const CampSubtitle = styled.div`
  ${() => getFontStyles(FontFamily.BASE_FONT, FontSize.REG30, undefined, undefined)};
`;

export const LargeText = styled.p`
  text-align: center;
  ${() => getFontStyles(FontFamily.BASE_FONT, '50px', undefined, undefined)};
`;

export const DetailsWrapper = styled.div`
  margin: 0 10px;
  line-height: normal;
`;

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const IconBlocksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const LargeHyphen = styled.div`
  height: 4px;
  width: 40px;
  background-color: ${() => themeUtil(Prop.CONTENT, CPNT, ColorProfiles.SECONDARY)};
`;

export const IconBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const IconBlockIcon = styled(Icon)`
  height: 50px;

  svg {
    color: ${() => themeUtil(Prop.ICON, CPNT, ColorProfiles.SECONDARY)};
    fill: ${() => themeUtil(Prop.ICON, CPNT, ColorProfiles.SECONDARY)};
  }
`;

export const IconBlockLabel = styled.div`
  ${() => getFontStyles(FontFamily.BASE_FONT, '18px', undefined, undefined)};
`;
