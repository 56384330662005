import styled from 'styled-components';

import { Icon } from '@powdr/components';
import {
  ThemeProps as Prop, FontFamily, StaticProfiles,
  AlertColorMap,
  AlertType,
  Components,
} from '@powdr/constants';
import { componentColor, getFontStyles, themeUtil } from '@powdr/utils';

const CPNT = Components.TV_ALERTS;

const getColor = (alertType, prop) => (
  (alertType === AlertType.INFO)
    ? themeUtil(Prop.CONTENT, null, StaticProfiles.TV)
    : componentColor(
      AlertColorMap[alertType][prop],
      CPNT,
      prop,
      StaticProfiles.ALERTS,
    )
);

export const StyledTvAlerts = styled.div`
  width: 100%;
  height: 100%;
`;

export const TimestampIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`;

export const AlertIcon = styled(Icon)`
  height: 200px;
  width: 200px;


  svg {
    color: ${({ $alertType }) => getColor($alertType, Prop.BACKGROUND)} !important;
    fill: ${({ $alertType }) => getColor($alertType, Prop.BACKGROUND)} !important;
  }
`;

export const Timestamp = styled.div`
  display: flex;
  flex-direction: column;
  color: ${() => themeUtil(Prop.CONTENT, null, StaticProfiles.TV)};
`;

export const TimestampDate = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '65px')};
`;

export const TimestampTime = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '65px')};
`;

export const Message = styled.div`
  ${() => getFontStyles(FontFamily.PRIMARY_FONT, '100px')};
  margin-top: 75px;
  margin-right: 10%;

  * {
    color: ${() => themeUtil(Prop.CONTENT, null, StaticProfiles.TV)};
  }
`;
