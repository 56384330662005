/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';

import { LiftsTrailsMeasurementTypes } from '@powdr/constants';

import {
  Trails, Lifts, Snowfall, Temperature, WindSpeed,
  ParkingStatus, RoadStatus, TerrainStatus,
  LiftStatus, TrailStatus,
  CurrentTemp,
} from './components';
import { StyledDorMeasurementItem } from './styles';

const MeasurementTypes = {
  LIFTS_TRAILS_VALUE: 'lifts_trails_value',
  SNOW_REPORT_VALUE: 'snow_report_value',
  TEMPERATURE_VALUE: 'temperature_value',
  CURRENT_TEMP: 'current_temp',
  WIND_SPEED_VALUE: 'wind_speed_value',
  PARKING_STATUS: 'parking_status',
  ROAD_STATUS: 'road_status',
  TERRAIN_STATUS: 'terrain_status',
  LIFT_STATUS: 'lift_status',
  TRAIL_STATUS: 'trail_status',
};

export const DorMeasurementItem = ({
  className,
  parentColorProfile,
  icon,
  measurementLabel,
  measurementType,
  measurementDisplayType,
  ignoreLiftTypes,
  ignoreTrailTypes,
  ignoreGroomStatus,
  liftsTrailsMeasurement,
  snowReportMeasurement,
  road,
  sensor,
  terrain,
  lift,
  trail,
  season,
}) => {
  const MeasurementDataComponentMap = {
    [MeasurementTypes.SNOW_REPORT_VALUE]: Snowfall,
    [MeasurementTypes.TEMPERATURE_VALUE]: Temperature,
    [MeasurementTypes.WIND_SPEED_VALUE]: WindSpeed,
    [MeasurementTypes.PARKING_STATUS]: ParkingStatus,
    [MeasurementTypes.ROAD_STATUS]: RoadStatus,
    [MeasurementTypes.TERRAIN_STATUS]: TerrainStatus,
    [MeasurementTypes.LIFT_STATUS]: LiftStatus,
    [MeasurementTypes.TRAIL_STATUS]: TrailStatus,
    [MeasurementTypes.CURRENT_TEMP]: CurrentTemp,
    [LiftsTrailsMeasurementTypes.OPEN_LIFTS]: Lifts,
    [LiftsTrailsMeasurementTypes.OPEN_TRAILS]: Trails,
    [LiftsTrailsMeasurementTypes.MILES_OF_SKIING]: Trails,
    [LiftsTrailsMeasurementTypes.ACRES_OF_SKIING]: Trails,
  };
  const measurement = (measurementType === MeasurementTypes.LIFTS_TRAILS_VALUE)
    ? liftsTrailsMeasurement
    : measurementType;

  return (
    <StyledDorMeasurementItem className={className}>
      {(MeasurementDataComponentMap[measurement]) && React.createElement(
        MeasurementDataComponentMap[measurement],
        {
          icon,
          measurementDisplayType,
          measurement,
          measurementLabel,
          liftsTrailsMeasurement,
          parentColorProfile,
          ignoreLiftTypes,
          ignoreTrailTypes,
          ignoreGroomStatus,
          snowReportMeasurement,
          road,
          sensor,
          terrain,
          lift,
          trail,
          season,
        },
      )}
    </StyledDorMeasurementItem>
  );
};

DorMeasurementItem.propTypes = {
  className: PropTypes.string,
  parentColorProfile: PropTypes.string,
  icon: PropTypes.string,
  measurementLabel: PropTypes.string,
  measurementType: PropTypes.string,
  measurementDisplayType: PropTypes.string,
  ignoreLiftTypes: PropTypes.arrayOf(PropTypes.string),
  ignoreTrailTypes: PropTypes.arrayOf(PropTypes.string),
  ignoreGroomStatus: PropTypes.arrayOf(PropTypes.string),
  liftsTrailsMeasurement: PropTypes.string,
  snowReportMeasurement: PropTypes.string,
  season: PropTypes.string,
  road: PropTypes.shape({
    value: PropTypes.string,
    uuid: PropTypes.string,
  }),
  terrain: PropTypes.shape({
    value: PropTypes.string,
    uuid: PropTypes.string,
  }),
  lift: PropTypes.shape({
    value: PropTypes.string,
    uuid: PropTypes.string,
  }),
  trail: PropTypes.shape({
    value: PropTypes.string,
    uuid: PropTypes.string,
  }),
  sensor: PropTypes.string,
};

DorMeasurementItem.defaultProps = {
  className: null,
  parentColorProfile: null,
  icon: null,
  measurementLabel: null,
  measurementType: null,
  measurementDisplayType: null,
  ignoreLiftTypes: null,
  ignoreTrailTypes: null,
  ignoreGroomStatus: null,
  liftsTrailsMeasurement: null,
  snowReportMeasurement: null,
  road: null,
  terrain: null,
  lift: null,
  trail: null,
  sensor: null,
  season: null,
};
